import React, { Component } from 'react';
import Mainpage from './components/mainpage';
// import './css/styles.scss';
import './App.css';
import './css/styles.scss';
import './css/styles2.scss';
import './css/styles3.scss';

class App extends Component {
  state = {} 
  render() { 
    return (
      <React.Fragment>
        <Mainpage/>


      </React.Fragment>
    );
  }
}

export default App;