import React, { Component, useState, useRef } from 'react';
import logo from '../assets/logo.png';
import hero from '../assets/hero_image.png';
import dumbell from '../assets/dumbell.png';
import running from '../assets/runing.png';
import flame from '../assets/flame.svg';
import heart from '../assets/heartHealth.svg';
import bolero from '../assets/image1.png';
import tick from '../assets/tick.png';
import nike from '../assets/nike.png';
import nb from '../assets/nb.png';
import adidas from '../assets/adidas.png';
import image2 from '../assets/image2.png';
import image3 from '../assets/image3.png';
import image4 from '../assets/image4.png';
import heart2 from '../assets/heart.png';
import hero2 from '../assets/hero_image_back.png';
import image5 from '../assets/t-image2.jpg';
import linkedin from '../assets/linkedin.png';
import github from '../assets/github.png';
import instagram from '../assets/instagram.png';
import rate from '../assets/calories.png';
import bootstrapjs from '/node_modules/bootstrap/dist/js/bootstrap.js';
import NumberCounter from 'number-counter';
import emailjs from '@emailjs/browser';
import { testimonialsData } from '../data/testimonialsData';
import {motion} from 'framer-motion';

const Mainpage = () => {   

    // const transition = {type:'spring', duration: 3}

    const [selected,setSelected] = useState(0);
    const tLength = testimonialsData.length;
    const mobile = window.innerWidth <= 768 ? true :false;
    const [menuOpened,setMenuOpened] = useState(false);
    const form = useRef();

    function handleLeft(){
        selected === 0 ? setSelected(tLength - 1):
        setSelected((prev)=> prev -1 );
        // if (selected === 0){
        //     setSelected(tLength - 1);
        // }
        // else {
        //     setSelected((prev)=> prev - 1);
        // }
    };

    function handleRight(){
        selected === (tLength - 1) ? setSelected (0) :
        setSelected((prev)=>prev+1);
    }
  
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_5x4vykf', 'template_fsfkm8g', form.current, 'o4f99XcBGwv2m5oj_')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          console.log('submitted')
      };
  
   
          return (
 
            <React.Fragment>
                <div className='App'>

        
{/* HOMEE */}
                <section id='Home'>
                    <div className='row'>
                        <div className='col-sm-9 leftside mt-2'>
                            <div className='container'>
                                <nav className='hola navbar-expand-sm
                                '  >
                                <div className='d-flex align-items-center '>
                                <div className='navbar-brand'>
                                    <img src = {logo} className = 'w-50'/>
                                </div>

                                {/* <button className="togla navbar-toggler ms-auto d-lg-none" type="button" data-bs-target="#navmenu" data-bs-toggle="collapse">
                                <span className="navbar-toggler-icon"></span>
                                </button> */}

                                {menuOpened===false && mobile === true ?
                                <div className='fixed'>
                                    <i onClick={()=> setMenuOpened(true)} className='fa fa-bars'></i>
                                </div>
                                : 
                                <div className='flextog'>
                                <div className="toglaa navbar-collapse" id="navmenu">
                                {/* <div className='navbar me-auto' id='content'> */}
                                    <ul className='navbar-nav' >
                                        <li className='nav-item' >
                                            <a                   
                                            onClick={()=>setMenuOpened(false)}
                                            href="#Home" className=' nav-link'>
                                                Home
                                            </a>
                                        </li>
                                        <li className='nav-item'>
                                            <a 
                                             onClick={()=>setMenuOpened(false)}
                                             href="#Programs" className='nav-link'>
                                                Programs
                                            </a>
                                        </li>
                                        <li className='nav-item'>
                                            <a 
                                             onClick={()=>setMenuOpened(false)}
                                             href="#WhyUs" className='nav-link'>
                                                WhyUs
                                            </a>
                                        </li>
                                        <li className='nav-item'>
                                            <a  onClick={()=>setMenuOpened(false)}
                                            href="#Plans" className='nav-link'>
                                                Plans
                                            </a>
                                        </li>
                                        <li className='nav-item'>
                                            <a 
                                             onClick={()=>setMenuOpened(false)}
                                             href="#Testimonials" className='nav-link'>
                                                Testimonials
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                </div>
                                }
                                </div>
                                </nav>
                                <div className='d-flex d-sm-block justify-content-center'>
                                <div className='thebest'>
                                    <motion.div className='ocircle'
                                    initial={{left: mobile? '158px':'235px'}}
                                    whileInView = {{left:'8px'}}
                                    transition = {{
                                        type:'tween',
                                        duration: 2
                                    }}
                                    // transition = {transition}
                                    ></motion.div>
                                    <div className='best'>
                                        The Best Fitness Club In The Town
                                    </div>
                                </div>
                                </div>
                                <div className='shape mt-5'>
                                    <h1 className='fw-bold hh'> <span className='stroke-text'> SHAPE </span>YOUR</h1>
                                    <h1 className='fw-bold'>IDEAL BODY</h1>
                                </div>
                                <p className='op text-light fw-light mt-3'>In here we help you to shape your bodies to the ideal and live your life to the fullest</p>
                                <div className='firstbtn d-flex'>
                                    <a style={{textDecoration:'none'}} href="#input"><button className='lmbtn btn btn-sm bg-warning fw-bold'>Get Started</button>
                                    </a>
                                    <button className='btn btn-sm btn-outline-warning fw-bold '>Learn More</button>
                                </div>
                                    <motion.div className='calories'
                                    transition={{
                                        type:'tween',
                                        duration : 3
                                    }}
                                    initial={{right:'-1rem'}}
                                    whileInView={{right:'-23rem'}}
                                    >
                                        <img src={rate} />
                                        <div className='c1'>
                                            <span className='text-muted'>Calories Burned</span>
                                            <span>220 kcal</span>
                                        </div>
                                    </motion.div>
                                <div className='stats d-flex mt-5'>
                                    <div className='mx-2'>
                                        <h2 className='countdown'>
                                        <NumberCounter end={140} preFix='+' start={100} delay='3' />
                                        </h2>
                                        <h6 className='text-muted'>EXPERT COACHES</h6>
                                    </div>
                                    <div className='mx-2'>
                                        <h2> <NumberCounter start={100} end={320} delay='3' preFix='+' /> </h2>
                                        <h6 className='text-muted'>MEMBERS JOINED</h6>
                                    </div>
                                    <div className='mx-2'>
                                        <h2><NumberCounter start={10} end={50} delay='3' preFix='+' /> </h2>
                                        <h6 className='text-muted'>FITNESS PROGRAMS</h6>
                                    </div>

                                </div>

                            </div> 
                        </div>

                        <div className='col rightside'>
                            <div>
                                <a href="#input"><button className='btn btn-light mt-5 jb'>Join Now</button>
                                </a>
                                <motion.div className='bpm'
                                transition={{
                                    type:'tween',
                                    duration:1
                                }}
                                initial={{right:'-1rem'}}
                                whileInView={{right:'3rem'}}
                                >
                                    <img src={heart} alt="" />
                                    <div className='he'>
                                    <div className='text-muted'>Heart Rate</div> 
                                    <div>116 bpm</div>
                                    </div>
                                </motion.div>
                            </div >
                            <motion.img 
                            transition={{
                                type:'tween',
                                duration:3
                            }}
                            initial={{right:'1rem'}}
                            whileInView={{right:'18rem'}}
                            src={hero2} className='hero2'/>
                            <img src={hero} className = 'hero' />
                        </div>
                    </div>
                </section>


{/* PROGRAMS */}
                <section id='Programs'>
                    <div className='mt-5'>
                        <div className='d-block d-sm-flex justify-content-center align-items-center'>
                            <h1 className='mx-4 fw-bold stroke-text'>EXPLORE OUR</h1>
                            <h1 className='mx-4 fw-bold'>PROGRAMS</h1>
                            <h1 className='mx-4 fw-bold stroke-text'>TO SHAPE YOU</h1>
                        </div>
                        <div className='row row-cols-sm-3 row-cols-md-5 row-cols-1 text-light g-3 justify-content-center align-items-center p-3 '>
                            <div className='col card mx-2 p-4 cards'>
                                <img src={dumbell} className=''/><br/>
                                <h6 className='mb-3 fw-bold'> Strength Training </h6>
                                In this program you are trained to improve your strength through many exercises. <br/><br/> 
                                <a href=''>Join Now <i className='fa fa-arrow-right mx-3'></i></a> 
                                
                            </div>
                            <div className='col card mx-2 p-4'>
                                <img src={running} className=''/><br/>
                                <h6 className='mb-3 fw-bold'> Cardio Training </h6>
                                In this program you are trained to improve your strength through many exercises. <br/><br/> 
                                <a href=''>Join Now <i className='fa fa-arrow-right mx-3'></i></a> 
                                
                            </div>
                            <div className='col card mx-2 p-4'>
                                <img src={flame} className='flame'/><br/>
                                <h6 className='mb-3 fw-bold'> Fat Burning </h6>
                                In this program you are trained to improve your strength through many exercises. <br/><br/> 
                                <a href=''>Join Now <i className='fa fa-arrow-right mx-3'></i></a> 
                                
                            </div>
                            <div className='col card mx-2 p-4'>
                                <img src={heart} className=''/><br/>
                                <h6 className='mb-3 fw-bold'> Health Training </h6>
                                In this program you are trained to improve your strength through many exercises. <br/><br/> 
                                <a href=''>Join Now <i className='fa fa-arrow-right mx-3'></i></a>      
                            </div>                                                      
                        </div>
                    </div>
                </section>

{/* WHY US */}
           
    <section id='WhyUs'>
    <div className='mt-4 row row-cols-1 row-cols-md-2 g-4 whole'>
        <div className='col-7 justify-content-center align-items-center'>
            <div className='WhyUs d-sm-flex'>
                <div className='mx-3 d-sm-block'>
                    <img src={bolero} className='bolero' />
                </div>
                <div className=''>
                    <img src={image2} className='w-100 mb-3' />
                    <div className='d-flex'>
                        <img src={image3} className='w-50' />
                        <img src={image4} className='w-50 b2' />
                    </div>
                </div>
            </div>

        </div>

        <div className='ts col text-light'>
            <h6 id='orange'>SOME REASONS</h6>
            <h1 className='fw-bold'><span className='stroke-text'> WHY</span> CHOOSE US ? </h1>
            <p><img src={tick} className='tick'/>  OVER 140+ EXPERT COACHES</p>
            <p><img src={tick} className='tick'/>  OVER 140+ EXPERT COACHES</p>
            <p><img src={tick} className='tick'/>  OVER 140+ EXPERT COACHES</p>
            <p><img src={tick} className='tick'/>  OVER 140+ EXPERT COACHES</p>
        
            <p className='text-muted'> OUR PARTNERS</p>
            <div className='d-flex'> 
            <img src={nb} alt="nb" className='logos'/>
            <img src={adidas} alt="adidas" className='logos'/>
            <img src={nike} alt="nike" className='logos'/>

            </div>
        </div>

    </div>

    </section>



{/* PLANS */}
                <section id='Plans' className='mb-2'>
                    <div className='plans d-sm-flex justify-content-around mx-5'>
                        <h1 className='plans stroke-text'> READY TO START</h1>
                        <h1 className='plans'> YOUR JOURNEY </h1>
                        <h1 className='plans stroke-text'> NOW WITHUS </h1>
                    </div>
                    <div className='container'>
                        <div className='row g-4 justify-content-center align-items-center mt-3'>
                            <div className='col'>
                                <div className='card plancard'>
                                        <img src={heart} className=''/>
                                        <h6 className='py-3 fw-bold'>BASIC PLAN</h6>
                                        <h1 className='fw-bold mb-4'>$25</h1>
                                        <p className='planlogo'><img src={tick}/> 2 Hours of exercises</p>
                                        <p className='planlogo'><img src={tick}/>Free Consultation</p>
                                        <p className='planlogo mb-4'><img src={tick}/>Access to the community</p>
                                        <p className='mb-4'>See more benefits <i className='fa fa-arrow-right'></i> </p>
                                        <button className='btn bg-light cardbtn'>Join Now</button>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='card2 plancard'>
                                        <img src={running} className=''/>
                                        <h6 className='py-3 fw-bold'>PREMIUM PLAN</h6>
                                        <h1 className='fw-bold mb-4'>$30</h1>
                                        <p className='planlogo'><img src={tick}/> 2 Hours of exercises</p>
                                        <p className='planlogo'><img src={tick}/>Free Consultation</p>
                                        <p className='planlogo mb-4'><img src={tick}/>Access to the community</p>
                                        <p className='mb-4'>See more benefits <i className='fa fa-arrow-right'></i> </p>
                                        <button className='btn bg-light cardbtn'>Join Now</button>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='card plancard'>
                                        <img src={dumbell} className=''/>
                                        <h6 className='py-3 fw-bold'>PRO PLAN</h6>
                                        <h1 className='fw-bold mb-4'>$45</h1>
                                        <p className='planlogo'><img src={tick}/> 2 Hours of exercises</p>
                                        <p className='planlogo'><img src={tick}/>Free Consultation</p>
                                        <p className='planlogo mb-4'><img src={tick}/>Access to the community</p>
                                        <p className='mb-4'>See more benefits <i className='fa fa-arrow-right'></i> </p>
                                        <button className='btn bg-light cardbtn'>Join Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

{/* TESTIMONIALS */}
                <section id='Testimonials' className='mt-5'>
                    <div className='container-xxl p-4 testi'>
                    <div className='row row-cols-sm-2 row-cols-1 justify-content-center align-items-center'>
                        <div className='col'>
                            <h6 className='orange fw-bold mb-4'>TESTIMONIALS</h6>
                            <h1 className='fw-bold stroke-text'>WHAT THEY</h1>
                            <h1 className='fw-bold mb-4'>SAY ABOUT US</h1>
                            <motion.p 
                            key={selected}
                            transition={{
                                type:'tween',
                                duration:1
                            }}
                            initial={{opacity:0,x:-30}}
                            whileInView={{opacity:1,x:0}}
                            className='mb-4'>
                                {testimonialsData[selected].review}
                            </motion.p>
                            <h6 className='mb-5'><span className='orange'>{testimonialsData[selected].name}</span> - {testimonialsData[selected].status}</h6>
                        </div>
                        <div className='picturess'>
                        <div className=' d-flex pictures'>
                            <motion.img 
                            key={selected}
                            // initial={{left: mobile? '158px':'235px'}}
                            transition={{
                                type:'tween',
                                duration:1
                            }}
                            initial={{opacity:0,
                                x:mobile?10:100}}
                            whileInView={{opacity:1,x:0}}
                            // exit={{opacity:0,x:-100}}
                            src={testimonialsData[selected].image} className=''/>
                            {/* little Backgrounds */}
                            <motion.div className='orangeback'
                            transition={{
                                type:'spring',
                                duration : 2
                            }}
                            initial={{opacity:0,x:-100}}
                            whileInView={{opacity:1,x:0}}
                            ></motion.div>
                            <motion.div 
                            transition={{
                                type:'spring',
                                duration:2     
                            }}
                            initial={{opacity:0,x:100}}
                            whileInView={{opacity:1,x:0}}
                            className='orangeoutline'>
                            </motion.div>
                            <div className='arrows'>
                                <i 
                                style={{cursor:'pointer'}}
                                onClick={()=>handleLeft()} 
                                className='fa fa-arrow-left'></i>
                                <i 
                                style={{cursor:'pointer'}}
                                onClick={()=>handleRight()}
                                className='fa fa-arrow-right'></i>
                            </div>  
                        </div>
                        </div>
                    </div>
                    </div>
                </section>

{/* FOOTER */}

                <section id='footer'>                    
                    <div className='d-md-flex d-block justify-content-between align-items-end p-4'>
                        <div>
                        <hr className='line'/>
                        <h1 className='stroke-text'>READY TO <span className='text-light'>LEVEL UP <br />YOUR BODY </span> WITH US?</h1>
                        </div>
                        <div className='input'>
                            <form ref={form} onSubmit={sendEmail}>
                            <input id='input' type="email" name='user_email' placeholder='Enter your Email Address Here'/>
                            <button className='btn'>Join Now</button>
                            </form>
                        </div>
                    </div>
                </section>

                <section id='last'>
                    <hr  className='mt-5 lline'/>
                    <div className='fle'>
                        <img src={github}/>
                        <img className='fle2' src={instagram}/>
                        <img src={linkedin}/>
                    </div>
                    <div className='text-center'>
                    <img src={logo}  />
                    </div>
                </section>





                </div>

                <script src={bootstrapjs}></script>
                
            </React.Fragment>
        );
    }

 
export default Mainpage;
